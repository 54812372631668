// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-cart-index-jsx": () => import("./../node_modules/@smplslaps/gatsby-theme-storefront-shopify/src/templates/cart/index.jsx" /* webpackChunkName: "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-cart-index-jsx" */),
  "component---src-smplslaps-gatsby-theme-storefront-shopify-templates-main-index-jsx": () => import("./../src/@smplslaps/gatsby-theme-storefront-shopify/templates/main/index.jsx" /* webpackChunkName: "component---src-smplslaps-gatsby-theme-storefront-shopify-templates-main-index-jsx" */),
  "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-catalog-index-jsx": () => import("./../node_modules/@smplslaps/gatsby-theme-storefront-shopify/src/templates/catalog/index.jsx" /* webpackChunkName: "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-catalog-index-jsx" */),
  "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-product-index-jsx": () => import("./../node_modules/@smplslaps/gatsby-theme-storefront-shopify/src/templates/product/index.jsx" /* webpackChunkName: "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-product-index-jsx" */),
  "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-policy-index-jsx": () => import("./../node_modules/@smplslaps/gatsby-theme-storefront-shopify/src/templates/policy/index.jsx" /* webpackChunkName: "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-templates-policy-index-jsx" */),
  "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-pages-404-jsx": () => import("./../node_modules/@smplslaps/gatsby-theme-storefront-shopify/src/pages/404.jsx" /* webpackChunkName: "component---node-modules-smplslaps-gatsby-theme-storefront-shopify-src-pages-404-jsx" */)
}

